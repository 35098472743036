import * as React from 'react'
import { graphql } from 'gatsby'
import { Box, Chip, Container, Grid, Typography } from '@mui/material'
import { Link } from "gatsby"
import Breadcrumbs from '../components/Breadcrumbs'
import RichText from '../components/RichText'
import Content from '../components/Content'
import Seo from '../components/Seo'
import buildOgpTags from '../services/buildOgpTags'
import buildMetaData from '../services/buildMetaData'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { linkResolver } from '../utils/linkResolver'
import ArticleCard from '../components/cards/ArticleCard'
import { useTranslation } from '../contexts/TranslationProvider'
import addToSchema from '../services/addToSchema'


const TeamMemberTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const page = data.page || {}
  const articles = data?.articles?.edges.map(({ node }) => node) || {}

  const getServiceBadges = (services) => {
    const list = services
      .filter((s) => s?.service?.document)
      .map((s) => s.service.document)

    if (list.length <= 0) {
      return <></>
    }

    return <Grid
      spacing={1}
      container
      alignItems="center"
      justifyContent="center"
    >
      {list.map(service => <Grid item key={`service-chip-${service.id}`}>
        <Chip
          component={Link}
          to={linkResolver(service)}
          label={service.data.title.text}
          icon={<Box
            component={GatsbyImage}
            image={getImage(service.data.icon)}
            alt={service?.data?.icon?.alt ?? service.data.title.text} />}
          sx={{
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            WebkitMaskImage: '-webkit-radial-gradient(white, black);',
            fontSize: '14px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#FFEAEA',
            }
          }} />
      </Grid>)}
    </Grid>
  }

  const getSpecialisationBadges = (specialisations) => {
    const list = specialisations
      .filter((s) => s?.specialisation?.document)
      .map((s) => s.specialisation.document)

    if (list.length <= 0) {
      return <></>
    }

    return <Grid
      spacing={1}
      container
      alignItems="center"
      justifyContent="center">
      {list.map(spec => <Grid item key={`service-chip-${spec.id}`}>
        <Chip
          label={spec.data.title.text} sx={{
            backgroundColor: spec.data.color,
            fontSize: '12px',
          }} />
      </Grid>)}
    </Grid>
  }

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>

        <Grid item xs={12}>
          <br />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12} sm={3}>
              <Box sx={{

                background: '#F5F5F5',
                borderRadius: '10px',
                WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                display: 'flex',
                alignItems: 'center',

                justifyContent: 'center'
              }}>
                <Box
                  component={GatsbyImage}
                  image={getImage(page.data.image.gatsbyImageData)}
                  alt={page.data.image.alt ?? 'page header icon'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <RichText
                sx={{
                  textAlign: 'center'
                }}
                content={page.data.title.richText}
              />
              {getSpecialisationBadges(page.data.specialisations)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          {getServiceBadges(page.data.services)}
          <br />
        </Grid>
      </Grid>
    </Container>

    <Content doc={page} />

    <Container maxWidth="lg">
      <br />
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h2">
            {`${t('articlesWrittenBy')} ${page.data.title.text}`}
          </Typography>
        </Grid>
        <Grid item >
          <Grid container spacing={2}>
            {articles.map(article => <Grid item xs={12} sm={4} key={`article-${article.id}`}>
              <ArticleCard article={article} />
            </Grid>)}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  // Physicians
  const specialisations = page.data.specialisations
    .filter((s) => s?.specialisation?.document)
    .map((s) => s.specialisation.document)
    .map((s) => s.data.title.text)

  const services = page.data.services
    .filter((s) => s?.service?.document)
    .map((s) => s.service.document)
    .map((s) => s.data.title.text)

  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "Physician",
    name: page.data.title.text,
    medicalSpecialty: specialisations[0] ? specialisations[0] : '',
    "image": {
      "@type": "ImageObject",
      "name": page.data.image.alt,
      "Url": page.data.image.url,
      "height": page.data.image.dimensions.height,
      "width": page.data.image.dimensions.width,
      "representativeOfPage": "True",
    },
    "url": page.url,
    availableService: services,
    telephone: "40773856287",
    priceRange: "$$",
  })

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query teamMemberQuery($id: String, $lang: String, $uid: String) {
    page: prismicTeamMember(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      id
      uid
      lang
      url
      type      
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText
          text
        }    
        description {
          richText
        }
        image {
          alt
          url
          dimensions{
            height
            width
          }
          gatsbyImageData
        }
        specialisations {
          specialisation {
            document {
              ... on PrismicSpecialisation {
                id
                uid
                data {
                  color
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        services {
          service {
            document {
              ... on PrismicService {
                id
                uid
                lang
                type
                data {
                  title {
                    text
                  }
                  icon {
                    alt
                    gatsbyImageData(width: 20)
                  }
                }
              }
            }
          }
        }    



        body {
          ... on PrismicTeamMemberDataBodySimpleText {
            id
            primary {
              text {
                richText
                text
              }
            }
            slice_type
          }


          ... on PrismicTeamMemberDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
            }
          }


          ... on PrismicTeamMemberDataBodyTextWithIcon {
            id
            slice_type
            primary {
              icon_image {
                gatsbyImageData
                alt
              }
              text {
                richText
                text
              }
            }
          }


          ... on PrismicTeamMemberDataBodyWrappedImage {
            id
            slice_type
            slice_label
            primary {
              wrapped_image_image_size
              wrapped_image_text_size
              wrapped_image_text_background_full
              image_position
              wrapped_image {
                alt
                gatsbyImageData
              }
              wrapped_text {
                richText
                text
              }
            }
          }



          ... on PrismicTeamMemberDataBodyAd {
            id
            slice_type
            items {
              ad_size
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }


                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }





          ... on PrismicTeamMemberDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }



          ... on PrismicTeamMemberDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }


          ... on PrismicTeamMemberDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }


        }
      }
    }


    articles: allPrismicArticle(
      limit: 3
      sort: {order: DESC, fields: first_publication_date}
      filter: {lang: {eq: $lang}, data: {author: {uid: {eq: $uid}}}}
    ) {
      edges {
        node {
          lang
          uid
          id
          type
          data {
            title {
              text
            }
            short_description {
              text
            }            
            author {
              document {
                ... on PrismicTeamMember {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            image {
              alt
              gatsbyImageData(width: 400)
            }
            body {
              ... on PrismicArticleDataBodySimpleArticleText {
                id
                slice_type
                primary {
                  text {
                    richText
                    text
                  }
                }
              }
              ... on PrismicArticleDataBodyWrappedImage {
                id
                slice_type
                primary {
                  wrapped_image_image_size
                  wrapped_image_text_size
                  wrapped_image_text_background_full
                  image_position
                  wrapped_image {
                    alt
                    gatsbyImageData
                  }
                  wrapped_text {
                    richText
                    text
                  }
                }
              }



              
            }
          }
        }
      }
    }
  }
`

export default TeamMemberTemplate